import { useRouter } from 'next/router';

import { AccountVisibleBanner } from '@/features/account/ui/account-visible-banner';

import { FullPurchaseFragment } from '@graphql/requests';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { PATHS } from '@ui-furniture/constants/paths';
import { VStack, HStack, Text, Em, Stack } from '@ui-furniture/elements';
import { useCurrentUser } from '@ui-furniture/hooks/use-current-user';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';
import { usePurchases } from '@ui-furniture/hooks/use-purchases';
import { useSales } from '@ui-furniture/hooks/use-sales';
import { useStateModalControls } from '@ui-furniture/lib/modal';
import { ChildAnimation } from '@ui-furniture/ui/animation/child';
import { PanelContainer } from '@ui-furniture/ui/layout/panel-container';
import { PanelContent } from '@ui-furniture/ui/layout/panel-content';

import { AfterSomethingSpecific } from '../ui/after-something-specific';
import { ExistingItemsSection } from '../ui/existing-items-section';
import { PropertyCard } from '../ui/property-card';
import { PropertySaleUnavailableModal } from '../ui/property-sale-unavailable-modal';

export const HomepageView = () => {
  const { reportEvent } = useAnalyticsReportingService();

  const { register } = useFlowControls();

  const { push } = useRouter();

  const { items } = useCurrentUser();
  const { purchases } = usePurchases();
  const { sales } = useSales();

  const firstPurchaseFound: FullPurchaseFragment | undefined = purchases[0];
  const firstSaleFound = sales[0];

  const propertySaleUnavailableControls = useStateModalControls();

  const propertySaleCardAction = async () => {
    if (firstSaleFound) {
      reportEvent({
        name: 'Click',
        on: 'Property Sale Dashboard Card - Continue Improving',
      });
      push(PATHS.webApp.createRoute.property(firstSaleFound.collectionKey));
      return;
    }
    register('create-sale', {
      registerTracking: [
        {
          name: 'Click',
          on: 'Property Sale Dashboard Card - Start Now',
        },
        {
          name: 'Sale Started',
        },
      ],
    });
    push(PATHS.webApp.createSale);
  };

  const propertyPurchaseCardAction = async () => {
    if (firstPurchaseFound) {
      reportEvent({
        name: 'Click',
        on: 'Property Purchase Dashboard Card - Continue Improving',
      });
      push(PATHS.webApp.createRoute.property(firstPurchaseFound.collectionKey));
    } else {
      register('create-purchase', {
        registerTracking: [
          {
            name: 'Click',
            on: 'Property Purchase Dashboard Card - Start Now',
          },
          {
            name: 'Purchase Started',
          },
        ],
      });
      push(PATHS.webApp.createPurchase);
    }
  };

  const bothCardAction = async () => {
    if (firstPurchaseFound) {
      reportEvent({
        name: 'Click',
        on: 'Doing Both? Dashboard Card - Continue Improving',
      });
      push(PATHS.webApp.createRoute.property(firstPurchaseFound.collectionKey));
    } else {
      register('create-move', {
        registerTracking: [
          {
            name: 'Click',
            on: 'Doing Both? Dashboard Card - Start Now',
          },
          {
            name: 'Move Started',
          },
        ],
      });
      push(PATHS.webApp.createMove);
    }
  };

  return (
    <>
      <PanelContainer>
        <AccountVisibleBanner withIcon />
        <PanelContent contentWidth='lg'>
          <VStack alignItems='center' spacing='xl'>
            <ChildAnimation>
              <Text variant='h3' as='h1' textAlign='center'>
                <Em>How can we help you today?</Em>
              </Text>
            </ChildAnimation>
            <ChildAnimation>
              <VStack spacing='sm' textAlign='center' maxW='xl'>
                <Text>
                  Our platform is a free tool that helps you understand and
                  complete the home buying and selling process quickly and
                  easily.
                </Text>
              </VStack>
            </ChildAnimation>
            <VStack w='2xl' spacing='xl' maxW='100%'>
              <VStack>
                <Stack
                  role='listbox'
                  justifyContent='center'
                  alignItems={{ _: 'stretch', tablet: 'center' }}
                  flexDirection={{ _: 'column', tablet: 'row' }}
                  w='100%'
                >
                  <ChildAnimation>
                    <PropertyCard
                      cardType='propertyPurchase'
                      cardAction={propertyPurchaseCardAction}
                      data={firstPurchaseFound}
                    />
                  </ChildAnimation>
                  <ChildAnimation>
                    <PropertyCard
                      cardType='propertySale'
                      cardAction={propertySaleCardAction}
                      data={firstSaleFound}
                    />
                  </ChildAnimation>
                </Stack>
                <HStack>
                  <ChildAnimation flex='1'>
                    <PropertyCard
                      cardType='both'
                      cardAction={bothCardAction}
                      data={firstPurchaseFound}
                    />
                  </ChildAnimation>
                </HStack>
              </VStack>
              {items.length ? (
                <ChildAnimation>
                  <ExistingItemsSection items={items} />
                </ChildAnimation>
              ) : null}
              <ChildAnimation>
                <AfterSomethingSpecific />
              </ChildAnimation>
            </VStack>
          </VStack>
        </PanelContent>
      </PanelContainer>
      <PropertySaleUnavailableModal
        controls={propertySaleUnavailableControls}
        managePropertyPurchase={propertyPurchaseCardAction}
      />
    </>
  );
};
