import React, { FC, forwardRef, PropsWithChildren } from 'react';

// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import Slider, { CustomArrowProps, Settings } from 'react-slick';

import ArrowLeft from '@ui-furniture/assets/iconly/Arrow - Left 2.svg';
import ArrowRight from '@ui-furniture/assets/iconly/Arrow - Right 2.svg';
import { styled, StyledProps, x } from '@ui-furniture/style';

const breakpoint = 'tablet';

const CarouselContainer = styled.divBox`
  .slick-dots {
    li {
      button::before {
        transition: base;
        color: palette-text;
      }
      &:hover {
        button::before {
          opacity: 0.75;
        }
      }
      &.slick-active {
        button::before {
          opacity: 1;
        }
      }
    }
  }
`;

const SlickArrowContainer = styled.divBox`
  transition: base;
  color: palette-text;
  cursor: pointer;
  z-index: 2;
  &:hover {
    color: palette-text;
  }
  &.slick-disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &:before {
    display: none;
    content: none;
  }
`;

const arrowVariants = {
  prev: {
    ArrowSvg: ArrowLeft,
    arrowStyleProps: {
      left: { _: 10, [breakpoint]: '-25px' },
      top: { _: 'unset', [breakpoint]: '50%' },
      bottom: { _: '-34px', [breakpoint]: 'unset' },
    },
    styleProps: {
      transform: {
        _: 'translateX(0)',
        hover: 'translateX(-5px)',
      },
    },
  },
  next: {
    ArrowSvg: ArrowRight,
    arrowStyleProps: {
      right: { _: 10, [breakpoint]: '-25px' },
      top: { _: 'unset', [breakpoint]: '50%' },
      bottom: { _: '-34px', [breakpoint]: 'unset' },
    },
    styleProps: {
      transform: {
        _: 'translateX(0)',
        hover: 'translateX(5px)',
      },
    },
  },
} as const satisfies Record<
  string,
  {
    ArrowSvg: FC;
    arrowStyleProps: StyledProps;
    styleProps: StyledProps;
  }
>;

const Arrow: FC<
  CustomArrowProps & {
    type: keyof typeof arrowVariants;
  }
> = ({ type, ...arrowProps }) => {
  const { ArrowSvg, arrowStyleProps, styleProps } = arrowVariants[type];
  return (
    <SlickArrowContainer {...arrowProps} {...arrowStyleProps} w='20px' h='20px'>
      <x.div transition='base' {...styleProps}>
        <ArrowSvg />
      </x.div>
    </SlickArrowContainer>
  );
};

export const Carousel = forwardRef<
  Slider,
  PropsWithChildren<{
    settings?: Partial<Settings>;
  }>
>(({ settings: carouselSettings, children }, ref) => {
  const settings: Settings = {
    slidesToShow: 3,
    initialSlide: 0,
    swipeToSlide: true,
    infinite: false,
    prevArrow: <Arrow type='prev' />,
    nextArrow: <Arrow type='next' />,
    ...carouselSettings,
  };

  return (
    <CarouselContainer
      className='slider-container'
      mb={{ _: '34px', [breakpoint]: 'unset' }}
    >
      <Slider ref={ref} {...settings}>
        {children}
      </Slider>
    </CarouselContainer>
  );
});
