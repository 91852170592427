import { FC } from 'react';

import { PersonalProtectPolicies } from '@graphql/requests';

import ShieldIcon from '@ui-furniture/assets/iconly/solid/Shield - 13.svg';

import { ItemCard } from './card';

export const PersonalProtectPolicyItem: FC<{
  item: PersonalProtectPolicies;
}> = ({ item: { itemId } }) => {
  return (
    <ItemCard
      action={{
        type: 'link',
        href: `/protect/view/${itemId}`,
      }}
      title='Your Protect Policy'
      description={itemId}
      Icon={ShieldIcon}
      iconColor='white'
      bgColor='#74CCF2'
    />
  );
};
