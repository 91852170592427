import { FC } from 'react';

import { ProtectInvitations } from '@graphql/requests';

import ShieldIcon from '@ui-furniture/assets/iconly/solid/Shield - 13.svg';

import { ItemCard } from './card';

export const ProtectInvitationItem: FC<{
  item: ProtectInvitations;
}> = ({ item: { itemId } }) => {
  return (
    <ItemCard
      action={{
        type: 'link',
        href: `/protect/view/${itemId}`,
      }}
      title='Your Protect Policy Invitation'
      description={itemId}
      Icon={ShieldIcon}
      iconColor='white'
      bgColor='#74CCF2'
    />
  );
};
