import { FC, useCallback } from 'react';

import { MortgageFlow } from '@/features/mortgage-flow/views/mortgage-flow';
import { x } from '@/style';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { LaunchCard } from '@ui-furniture/common/cards/launch-card';
import { PATHS } from '@ui-furniture/constants/paths';
import { VStack, Text, Em } from '@ui-furniture/elements';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';
import { useQueryParamModalControls } from '@ui-furniture/lib/modal';

export const AfterSomethingSpecific: FC = () => {
  const mortgageControls = useQueryParamModalControls({
    id: 'mortgage-flow',
  });
  const { reportObjective } = useAnalyticsReportingService();
  const { register } = useFlowControls();

  const handleFindConveyancerClick = useCallback(() => {
    reportObjective({
      stage: 'start',
      source: 'home',
      category: 'Conveyancing',
      name: 'Conveyancing - Find Conveyancer Card on Home',
    });
  }, []);

  const handleMortgageClick = useCallback(() => {
    register('book-mip-appointment', {
      registerTracking: [
        {
          name: 'Dashboard Event',
          action: 'Started',
          task: 'MIP',
        },
        {
          name: 'MIP Started',
        },
      ],
      successTracking: [
        {
          name: 'Dashboard Event',
          action: 'Completed',
          task: 'MIP',
        },
        {
          name: 'MIP Completed',
        },
      ],
      failTracking: [
        {
          name: 'Dashboard Event',
          action: 'Failed',
          task: 'MIP',
        },
        {
          name: 'MIP Failed',
        },
      ],
      closeTracking: [
        {
          name: 'Dashboard Event',
          action: 'Closed',
          task: 'MIP',
        },
        {
          name: 'MIP Closed',
        },
      ],
    });
    mortgageControls.open();
  }, []);

  return (
    <VStack w='100%' gap='lg'>
      <VStack textAlign='center' gap='xs'>
        <Text variant='subTitle'>After something specific?</Text>
        <Text variant='body'>
          Get cash-back on trusted suppliers or protect your offer from falling
          through
        </Text>
      </VStack>

      <x.div
        display='flex'
        minWidth='100%'
        flexDirection={{ _: 'column', phablet: 'row' }}
        justifyContent={{ _: undefined, phablet: 'space-between' }}
        alignItems={{ _: 'center', phablet: undefined }}
        gap='md'
      >
        <LaunchCard
          action={{
            type: 'link',
            href: PATHS.webApp.conveyancing,
            onClick: handleFindConveyancerClick,
          }}
          type='standard'
          Content={
            <>
              Find a <br />
              <Em>conveyancer</Em>
            </>
          }
        />
        <>
          <LaunchCard
            action={{
              type: 'button',
              onClick: handleMortgageClick,
            }}
            type='standard'
            Content={
              <>
                Organise a <br />
                <Em>mortgage</Em>
              </>
            }
          />
          <MortgageFlow controls={mortgageControls} />
        </>
        <>
          <LaunchCard
            action={{
              type: 'link',
              href: '/protect/create',
            }}
            type='standard'
            Content={
              <>
                Protect an offer with <br />
                <Em>HousecureProtect</Em>
              </>
            }
          />
        </>
      </x.div>
    </VStack>
  );
};
