import { ElementType, FC } from 'react';

import { Card, HStack, Text, VStack } from '@ui-furniture/elements';
import { x } from '@ui-furniture/style';
import { Clickable, ClickableAction } from '@ui-furniture/ui/clickable';

export const ItemCard: FC<{
  Icon: ElementType;
  iconColor: string;
  bgColor: string;
  title: string;
  description: string;
  action: ClickableAction;
}> = ({ Icon, iconColor, bgColor, title, description, action }) => {
  return (
    <Clickable action={action}>
      <Card clickStyleVariant='scale' w='100%' overflow='hidden' h='80px'>
        <HStack justifyContent='stretch'>
          <x.div
            as={x.span}
            display='flex'
            alignItems='center'
            justifyContent='center'
            aspectRatio={'1 / 1'}
            h='80px'
            bg={bgColor}
            color={iconColor}
          >
            <Icon
              style={{
                width: '56px',
                height: '56px',
                filter: 'drop-shadow(4px 4px 4px rgba(65, 79, 101, 0.15))',
              }}
            />
          </x.div>
          <VStack px='sm' textAlign='left' gap='0' justifyContent='center'>
            <Text variant='bodyHeavy' lineHeight='1.3'>
              {title}
            </Text>
            <Text>{description}</Text>
          </VStack>
        </HStack>
      </Card>
    </Clickable>
  );
};
