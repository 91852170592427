import { FC } from 'react';

import {
  FullPurchaseFragment,
  FullSaleFragment,
} from '@graphql/generated/graphql';

import { Text, Em, Card, HStack, VStack } from '@ui-furniture/elements';
import { Clickable } from '@ui-furniture/ui/clickable';
import { LineIcon } from '@ui-furniture/ui/line-icon';

const constants = {
  propertyPurchase: {
    emTitle: 'My Buying Journey',
    profileDef: 'buyer',
    description:
      'Whether you’ve just started looking or are about to complete, we can help you',
    tearDropIcon: 'yellowTearDrop',
  },
  propertySale: {
    emTitle: 'My Selling Journey',
    profileDef: 'seller',
    description:
      'We’ll help you gather your documents and prepare for a smooth sale',
    tearDropIcon: 'orangeTearDrop',
  },
  both: {
    emTitle: 'Or Are You Moving?',
    profileDef: 'buyer',
    description:
      'We’ll streamline the process to help you get from where you are to where you want to be',
    tearDropIcon: 'yellowTearDrop',
  },
} as const;

export const PropertyCard: FC<{
  cardType: 'propertyPurchase' | 'propertySale' | 'both';
  cardAction: () => Promise<void>;
  data?: FullPurchaseFragment | FullSaleFragment;
}> = ({ cardType, data, cardAction }) => {
  const { emTitle, description } = constants[cardType];

  return (
    <Clickable
      variant='blank'
      action={{
        type: 'button',
        onClick: cardAction,
      }}
      w='100%'
    >
      <Card
        variant='highlight'
        clickStyleVariant='scale'
        display='flex'
        flexDirection='column'
        p='2px'
        borderRadius='sm'
      >
        <VStack p='lg' alignItems='center'>
          <Text variant='bodyBig' as='h3' textAlign='center' fontWeight={600}>
            <Em>{emTitle}</Em>
          </Text>

          {description && (
            <Text variant='bodySmall' textAlign='center'>
              {description}
            </Text>
          )}

          <HStack justifyContent='center' alignItems='center'>
            <Text variant='labelSmall'>
              {data ? 'Continue Improving' : 'Start Now'}
            </Text>
            <LineIcon name='rightArrowCircle' size='sm' />
          </HStack>
        </VStack>
      </Card>
    </Clickable>
  );
};
