import { ComponentProps, FC } from 'react';

import { Card, HStack, Text } from '@ui-furniture/elements';
import { Clickable, ClickableAction } from '@ui-furniture/ui/clickable';
import { LineIcon } from '@ui-furniture/ui/line-icon';

const LaunchCardTypeMap = {
  standard: {
    card: {
      clickStyleVariant: 'scale',
    },
    icon: <LineIcon name='arrowRightCircle' size='md' />,
  },
  highlighted: {
    card: {
      clickStyleVariant: 'scale',
      variant: 'highlight',
    },
    icon: <LineIcon name='arrowRightCircle' size='md' />,
  },
  'coming-soon': {
    card: {
      variant: 'disabled',
    },
    icon: <LineIcon name='hourglass' size='md' />,
  },
  locked: {
    card: {
      clickStyleVariant: 'scale',
      variant: 'regular',
    },
    icon: <LineIcon name='lockSquare' size='md' />,
  },
} as const satisfies Record<
  string,
  {
    card: Pick<ComponentProps<typeof Card>, 'variant' | 'clickStyleVariant'>;
    icon: JSX.Element;
  }
>;

export const LaunchCard: FC<{
  type?: keyof typeof LaunchCardTypeMap;
  isInsideCard?: boolean;
  action?: ClickableAction;
  Content: JSX.Element;
}> = ({ type = 'standard', isInsideCard, action, Content }) => {
  const { card: cardProps } = LaunchCardTypeMap[type];
  return (
    <Clickable action={action || null} w='100%' maxWidth='sm'>
      <Card {...cardProps} isInsideCard={isInsideCard} p='md' w='100%'>
        <HStack alignItems='center' justifyContent='space-between'>
          <Text variant='labelSmall' textAlign='start'>
            {Content}
          </Text>
          {LaunchCardTypeMap[type].icon}
        </HStack>
      </Card>
    </Clickable>
  );
};
