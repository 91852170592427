import { FC } from 'react';

import { ItemFragment } from '@graphql/requests';

import { ConveyancingRecommendationItem } from './conveyancing-recommendation-item';
import { PersonalProtectPolicyItem } from './personal-protect-policy-item';
import { ProtectInvitationItem } from './protect-invitation-item';

const ItemComponent: Record<
  NonNullable<ItemFragment['__typename']>,
  FC<{
    item: ItemFragment;
  }>
> = {
  PersonalProtectPolicies: PersonalProtectPolicyItem as any,
  ProtectInvitations: ProtectInvitationItem as any,
  ConveyancingRecommendations: ConveyancingRecommendationItem as any,
};

export const Item: FC<{
  item: ItemFragment;
}> = ({ item }) => {
  if (!item.__typename) return null;
  const Component = ItemComponent[item.__typename];
  return <Component item={item} />;
};
