import { useGetSalesQuery } from '@graphql/requests';

import { useCurrentUser } from './use-current-user';

export const useSales = () => {
  const { isAuthenticated } = useCurrentUser();

  const { data, loading } = useGetSalesQuery({
    skip: !isAuthenticated,
  });

  return {
    loading,
    sales: data?.sales || [],
  };
};
