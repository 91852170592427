import { FC } from 'react';

import { useScreens, x } from '@/style';

import { ItemFragment } from '@graphql/requests';

import { Carousel } from '@ui-furniture/common/carousel';
import { Item } from '@ui-furniture/common/items/item';
import { VStack, Text } from '@ui-furniture/elements';

export const ExistingItemsSection: FC<{
  items: Array<ItemFragment>;
}> = ({ items }) => {
  const { phablet } = useScreens();

  return (
    <VStack gap='lg' alignItems='stretch'>
      <VStack textAlign='center' gap='xs'>
        <Text variant='subTitle'>Carry on with your existing products</Text>
        <Text variant='body'>
          It looks like you’ve already starting making progress
        </Text>
      </VStack>

      <x.div w='100%'>
        <Carousel
          settings={{
            slidesToShow: 2,
            responsive: [
              {
                breakpoint: phablet,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          }}
        >
          {items.map((item) => (
            <x.div key={item.itemId} px='sm'>
              <Item item={item} />
            </x.div>
          ))}
        </Carousel>
      </x.div>
    </VStack>
  );
};
