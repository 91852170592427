import { FC } from 'react';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { Button, HStack, Text, VStack } from '@ui-furniture/elements';
import { Modal } from '@ui-furniture/lib/modal';
import { ModalControls } from '@ui-furniture/lib/modal/types/controls';

export const PropertySaleUnavailableModal: FC<{
  managePropertyPurchase: () => void;
  controls: ModalControls;
}> = ({ managePropertyPurchase, controls }) => {
  const { reportEvent } = useAnalyticsReportingService();

  return (
    <Modal controls={controls} variant='popover'>
      <VStack textAlign='center' alignItems='center'>
        <Text variant='subTitle'>
          Sorry, we aren’t currently helping sellers manage their sales
        </Text>
        <Text>
          Right now we are focused on helping good buyers manage their property
          purchases, but the ability to manage a sale is coming very soon!
        </Text>
        <Text>Shall we help you manage a purchase instead?</Text>
        <HStack pt='md'>
          <Button type='button' version='secondary' onClick={controls.close}>
            Nevermind
          </Button>
          <Button
            type='button'
            onClick={async () => {
              reportEvent({
                name: 'Click',
                on: 'Property sale unavailable - Manage a purchase instead',
              });
              managePropertyPurchase();
              controls.close();
            }}
          >
            Ok, manage a purchase
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
};
