import { FC } from 'react';

import { ConveyancingRecommendations } from '@graphql/requests';

import ScalesIcon from '@ui-furniture/assets/iconly/solid/Scales.svg';

import { ItemCard } from './card';

export const ConveyancingRecommendationItem: FC<{
  item: ConveyancingRecommendations;
}> = ({ item: { itemId } }) => {
  return (
    <ItemCard
      action={{
        type: 'link',
        href: `/conveyancing/recommendation/view/${itemId}`,
      }}
      title='Conveyancing Recommendations'
      description={itemId}
      Icon={ScalesIcon}
      iconColor='rgb(65,76,101, 0.6)'
      bgColor='#dfeb57'
    />
  );
};
